import { useContext } from 'react';

import { PaginationContext } from './PaginationContext';

/**
 * React hook for accessing pagination context.
 */
export const usePaginationContext = () => {
  const context = useContext(PaginationContext);
  if (context === undefined) {
    throw new Error('usePagination must be used within a PaginationProvider');
  }
  return context;
};
