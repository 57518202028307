import {
  ImageDataModel,
  ImagesPromiseFunc
} from '@komo-tech/core/models/ImageDataModel';
import {
  QrCodeCornerSquareType,
  QrCodeDotType,
  QrStylingOptions
} from '@komo-tech/ui/QrCode';
import { CSSProperties } from 'react';

import { BlockBackground } from '@/common/components/BlockBuilder/types/BlockBackground';
import { BlockItem } from '@/common/components/BlockBuilder/types/BlockItem';
import { BlockItemData } from '@/common/components/BlockBuilder/types/BlockItemData';
import { BlockItemSizeAutoBehaviour } from '@/common/components/BlockBuilder/types/BlockRenderAutoBehaviour';
import { BlockRenderSize } from '@/common/components/BlockBuilder/types/BlockRenderSize';
import { BlockSize } from '@/common/components/BlockBuilder/types/BlockSize';
import { BlockTypes } from '@/common/components/BlockBuilder/types/BlockTypes';
import { KomoUtmParamResolver } from '@/common/utils/Constants';

export class BlockQrCodeItem extends BlockItem<BlockQrCodeItemData> {
  type = BlockTypes.QrCode;

  constructor(props?: Partial<BlockQrCodeItem>) {
    props = props || {};
    super({
      childOptions: {
        enabled: false
      },
      sizeOptions: {
        aspectRatio: {
          enabled: true,
          locked: {
            disabled: true,
            value: () => ({ height: 1, width: 1 })
          }
        },
        height: { lockedAutoBehaviour: 'Fill' },
        width: { lockedAutoBehaviour: 'Fill' }
      }
    });
    Object.assign(this, this.sanitiseProps(props));
    this.setDefaults();
  }

  getData(): BlockQrCodeItemData {
    return new BlockQrCodeItemData(this.safeParseJsonData());
  }

  resolveUpdateData(change: Partial<BlockQrCodeItemData>) {
    return new BlockQrCodeItemData({
      ...this.getData(),
      ...change
    });
  }

  resolveImageAssetsFuncAsync(): ImagesPromiseFunc {
    const images: ImageDataModel[] = [];
    const data = this.getData();
    if (data.logo?.url) {
      images.push(data.logo);
    }

    return () => Promise.resolve(images);
  }

  getRenderData(scale: number, utmMedium?: string) {
    const data = this.getData();

    const containerStyle: CSSProperties = { position: 'relative' };

    return {
      qrOptions: data.resolveQrOptions({
        renderSize: this.renderSize,
        scale,
        utmMedium
      }),
      containerStyle
    };
  }

  static new(code: string, data?: Partial<Omit<BlockQrCodeItemData, 'url'>>) {
    return new BlockQrCodeItem({
      jsonData: JSON.stringify(
        new BlockQrCodeItemData({
          ...data,
          code
        })
      )
    });
  }
}

export class BlockQrCodeItemData extends BlockItemData {
  code: string;
  dotsColor: string;
  dotsVariant: QrCodeDotType;
  cornerSquareColor: string;
  cornerSquareVariant: QrCodeCornerSquareType;
  background: BlockBackground;
  logo?: ImageDataModel;

  constructor(props?: Partial<BlockQrCodeItemData>) {
    super();
    Object.assign(
      this,
      this.mergeDefaults({
        ...BlockQrCodeItemData.defaults,
        ...props
      })
    );
    this.setDefaults();
  }

  static defaults: Partial<BlockQrCodeItemData> = {
    dotsColor: '#000000',
    dotsVariant: 'square',
    cornerSquareColor: '#000000',
    cornerSquareVariant: 'square',
    background: new BlockBackground({ color: '#FFFFFF' })
  };

  protected setDefaults() {
    super.setDefaults();
    this.background = new BlockBackground(this.background);
    if (this.logo) this.logo = new ImageDataModel(this.logo);
    this.hAlign = undefined;
    this.vAlign = undefined;
  }

  getCodeWithUtm(medium: string) {
    if (!this.code) return null;
    return KomoUtmParamResolver(this.code, {
      utm: {
        type: 'Site',
        medium
      }
    });
  }

  resolveQrOptions(options: {
    renderSize: BlockRenderSize;
    scale: number;
    utmMedium?: string;
  }): Partial<QrStylingOptions> {
    const pX = this.padding.getX(1);
    const pY = this.padding.getY(1);
    const height =
      options.renderSize.height.value.value ||
      options.renderSize.height.max.value ||
      BlockSize.MIN_SIZE;
    const width =
      options.renderSize.width.value.value ||
      options.renderSize.width.max.value ||
      BlockSize.MIN_SIZE;

    const maxHeight = height - pY;
    const maxWidth = width - pX;
    const smallestSide = maxHeight < maxWidth ? maxHeight : maxWidth;
    return {
      height: smallestSide * options.scale,
      width: smallestSide * options.scale,
      data: !!options.utmMedium
        ? this.getCodeWithUtm(options.utmMedium)
        : this.code,
      image: this.logo?.url,
      backgroundOptions: {
        color: this.background.color
      },
      dotsOptions: {
        color: this.dotsColor,
        type: this.dotsVariant
      },
      cornersSquareOptions: {
        color: this.cornerSquareColor,
        type: this.cornerSquareVariant
      }
    };
  }

  defaultAutoBehaviour(): BlockItemSizeAutoBehaviour {
    return {
      height: 'Fill',
      width: 'Fill'
    };
  }
}
