import { BlockRenderType } from './BlockRenderType';
import { BlockTypes } from './BlockTypes';

export class BlockEditorElementProps {
  type?: BlockRenderType;
  itemType?: BlockTypes;
  itemId?: string;
  stageFlag?: string;
  extraData?: string;

  get isStage() {
    return !!this.stageFlag;
  }

  get isItem() {
    return !!this.itemId;
  }

  get dataAttributes(): Record<string, string> {
    const props: Record<string, string> = {
      ['data-block-entity']: 'true'
    };
    if (this.type) props['data-type'] = this.type;
    if (this.itemId) props['data-item-id'] = this.itemId;
    if (this.itemType) props['data-item-type'] = this.itemType;
    if (this.extraData) props['data-extra-data'] = this.extraData;
    if (this.stageFlag) props['data-stage-flag'] = 'true';
    return props;
  }

  constructor(props?: Partial<BlockEditorElementProps>) {
    props = props || {};
    Object.assign(this, props);
  }

  static tryGetFromElement(element: HTMLElement): BlockEditorElementProps {
    const isEntity = !!element?.dataset?.blockEntity;
    if (!isEntity && element?.parentElement) {
      return BlockEditorElementProps.tryGetFromElement(element.parentElement);
    }

    return new BlockEditorElementProps(element?.dataset as any);
  }

  static fromItem(id: string, itemType: BlockTypes, type: BlockRenderType) {
    return new BlockEditorDomDataPropsBuilder()
      .fromItem(id, itemType, type)
      .build();
  }

  static forStage(type: BlockRenderType) {
    return new BlockEditorDomDataPropsBuilder()
      .withStageFlag()
      .withType(type)
      .build();
  }

  static empty() {
    return new BlockEditorElementProps();
  }
}

export class BlockEditorDomDataPropsBuilder {
  props: BlockEditorElementProps;

  constructor() {
    this.props = new BlockEditorElementProps();
  }

  build() {
    return this.props;
  }

  fromItem(id: string, itemType: BlockTypes, renderType?: BlockRenderType) {
    this.props.itemType = itemType;
    this.props.itemId = id;
    if (renderType) {
      this.props.type = renderType;
    }
    return this;
  }

  withStageFlag() {
    this.props.stageFlag = 'true';
    return this;
  }

  withType(type: BlockRenderType) {
    this.props.type = type;
    return this;
  }

  withExtraData(extraData: string) {
    this.props.extraData = extraData;
    return this;
  }
}
