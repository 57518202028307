import { HeightWidth } from '@komo-tech/core/models/HeightWidth';
import { aspectRatioCss } from '@komo-tech/core/utils/size';
import { RectDataModel } from '@komo-tech/ui/hooks/useMeasure';
import isNil from 'lodash/isNil';
import { CSSProperties } from 'react';

import { BlockMarginPadding } from '../BlockMarginPadding';
import { BlockMeasurementUnit } from '../BlockMeasurementUnit';
import { BlockItemSizeAutoBehaviour } from '../BlockRenderAutoBehaviour';
import { BlockSize } from '../BlockSize';
import { BlockStackVariant } from '../BlockStackVariant';
import { BlockRenderSizeDimension } from './BlockRenderSizeDimension';

export class BlockRenderSize {
  height: BlockRenderSizeDimension;
  width: BlockRenderSizeDimension;
  aspectRatio?: HeightWidth;
  parentStack: BlockStackVariant = 'Vertical';

  get hasAspectRatio() {
    return !!this.aspectRatio;
  }
  constructor(props?: Partial<BlockRenderSize>) {
    props = props || {};
    Object.assign(this, props);
    this.height = new BlockRenderSizeDimension(props.height);
    this.width = new BlockRenderSizeDimension(props.width);
  }

  getStyle(options: {
    scale: number;
    autoBehaviour: BlockItemSizeAutoBehaviour;
  }): CSSProperties {
    const { autoBehaviour, scale } = options;
    const style: CSSProperties = {
      maxHeight: this.height.max.getCssValue(scale),
      maxWidth: this.width.max.getCssValue(scale),
      width: this.width.getSizeCssValue({
        scale,
        autoBehaviour: autoBehaviour.width
      }),
      height: this.height.getSizeCssValue({
        scale,
        autoBehaviour: autoBehaviour.height
      }),
      overflow: 'hidden',
      boxSizing: 'border-box'
    };

    if (
      this.height.value.isAuto &&
      this.width.value.isAuto &&
      this.hasAspectRatio
    ) {
      style.height = '100%';
      style.width = '100%';
      style.aspectRatio = aspectRatioCss(this.aspectRatio);
    }
    if (style.width === 'auto') {
      style.width = 'fit-content';
    }

    return style;
  }

  static auto() {
    return new BlockRenderSize({
      height: BlockRenderSizeDimension.auto(),
      width: BlockRenderSizeDimension.auto()
    });
  }

  static fromSize(size: HeightWidth) {
    return new BlockRenderSize({
      height: BlockRenderSizeDimension.fromPixels(size.height),
      width: BlockRenderSizeDimension.fromPixels(size.width)
    });
  }

  static resolveForItem({
    itemId,
    siblingsAndSelf = [],
    parent
  }: BlockRenderSizeItemResolveOptions): BlockRenderSize {
    if (!parent.padding) parent.padding = BlockMarginPadding.empty();
    if (!parent.stack) parent.stack = 'Vertical';

    let aspectRatio: HeightWidth | undefined = undefined;

    const height = new BlockRenderSizeDimension({
      max: parent.size.height.value.isAuto
        ? parent.size.height.max
        : parent.size.height.value
    });
    const width = new BlockRenderSizeDimension({
      max: parent.size.width.value.isAuto
        ? parent.size.width.max
        : parent.size.width.value
    });

    if (height.max.hasPixelValue) {
      height.max.value -= parent.padding.getY(1);
    }

    if (width.max.hasPixelValue) {
      width.max.value -= parent.padding.getX(1);
    }

    height.allowedMax = height.max?.value;
    width.allowedMax = width.max?.value;

    //Bail we should have some children
    const childCount = siblingsAndSelf?.length || 0;
    if (!childCount) {
      return new BlockRenderSize({ height, width, parentStack: parent.stack });
    }

    const item = siblingsAndSelf.find((x) => x.id === itemId);

    //Bail we should have found the item
    if (!item) {
      return new BlockRenderSize({ height, width, parentStack: parent.stack });
    }
    const itemSize = item.size;
    height.value = new BlockMeasurementUnit(itemSize.height);
    width.value = new BlockMeasurementUnit(itemSize.width);
    aspectRatio = itemSize.aspectRatio;

    //If we are the only child then bail early
    if (childCount === 1) {
      return new BlockRenderSize({
        aspectRatio,
        height,
        width,
        parentStack: parent.stack
      });
    }

    //Check if we have resolved the max height/width of our item
    let widthResolved = false;
    let heightResolved = false;

    const stackVertical = parent.stack === 'Vertical';
    const stackHorizontal = parent.stack === 'Horizontal';

    const canShrinkHeight = () =>
      stackVertical && height.max.value > 0 && !heightResolved;

    const canShrinkWidth = () =>
      stackHorizontal && width.max.value > 0 && !widthResolved;

    //if we can't shrink width or height we are done
    if (!canShrinkHeight() && !canShrinkWidth()) {
      return new BlockRenderSize({ height, width, parentStack: parent.stack });
    }

    //Allowed height/width will be used when resizing. we should give allowance
    //for a min size on all siblings
    const allowedMaxSubtractor = BlockSize.MIN_SIZE * (childCount - 1);
    if (canShrinkHeight()) {
      height.allowedMax = Math.max(0, height.allowedMax - allowedMaxSubtractor);
    }

    if (canShrinkWidth()) {
      width.allowedMax = Math.max(0, width.allowedMax - allowedMaxSubtractor);
    }

    //Will keep track of all siblings that are marked as auto height/width
    const siblingFactors: HeightWidth = {
      height: 0,
      width: 0
    };

    //Loop through all children in order and figure out the max height/width based
    //on specific pixel sizes of siblings
    for (let i = 0; i < childCount; ++i) {
      let itemPixelHeight: number = undefined;
      let itemPixelWidth: number = undefined;

      const child = siblingsAndSelf[i];
      const childSize = child.size;
      const isItem = child.id === item.id;

      //If we have no more max height then we cant shrink any more
      if (canShrinkHeight()) {
        const pixelHeight = childSize.height.value;
        if (!isNil(pixelHeight)) {
          if (isItem) {
            itemPixelHeight = pixelHeight;
          } else {
            height.max.value = Math.max(0, height.max.value - pixelHeight);
          }
        } else {
          ++siblingFactors.height;
        }
      }

      //If we have no more max width then we cant shrink any more
      if (canShrinkWidth()) {
        const pixelWidth = childSize.width.value;
        if (!isNil(pixelWidth)) {
          if (isItem) {
            itemPixelWidth = pixelWidth;
          } else {
            width.max.value = Math.max(0, width.max.value - pixelWidth);
          }
        } else {
          ++siblingFactors.width;
        }
      }

      //If we have found our item and it has height we want to santise max height
      if (itemPixelHeight) {
        heightResolved = true;
        if (height.max.value > itemPixelHeight) {
          height.max.value = itemPixelHeight;
        }
      }

      //If we have found our item and it has width we want to santise max width
      if (itemPixelWidth) {
        widthResolved = true;
        if (width.max.value > itemPixelWidth) {
          width.max.value = itemPixelWidth;
        }
      }
    }

    if (canShrinkHeight() && siblingFactors.height) {
      height.max.value /= siblingFactors.height;
    }

    if (canShrinkWidth() && siblingFactors.width) {
      width.max.value /= siblingFactors.width;
    }

    return new BlockRenderSize({
      aspectRatio,
      height,
      width,
      parentStack: parent.stack
    });
  }
}

export interface BlockRenderSizeItemDto {
  id: string;
  size: BlockSize;
  bounds?: RectDataModel;
}

export interface BlockRenderSizeParentDto {
  stack: BlockStackVariant;
  size: BlockRenderSize;
  padding: BlockMarginPadding;
}

export interface BlockRenderSizeItemResolveOptions {
  itemId: string;
  siblingsAndSelf: BlockRenderSizeItemDto[];
  parent: BlockRenderSizeParentDto;
}
