import { FC } from 'react';

import { BlockBackgroundRenderer } from '@/common/components/BlockBuilder/shared/BlockBackgroundRenderer';

import { useBlockViewer, useBlockViewerItem } from '../../Viewer/Context';
import { BlockItemChildrenViewRenderer } from '../BlockItemChildrenViewRenderer';
import { BlockLayoutItem } from './BlockLayoutItem';

export const BlockLayoutItemViewRenderer: FC = () => {
  const layout = useBlockViewerItem((x) => x.item) as BlockLayoutItem;
  const register = useBlockViewerItem((x) => x.registerElement);
  const stageScale = useBlockViewer((x) => x.stageScale);
  const { data, style } = layout.getRenderData(stageScale);
  return (
    <div style={style} ref={register}>
      <BlockBackgroundRenderer background={data.background} />
      <BlockItemChildrenViewRenderer />
    </div>
  );
};
