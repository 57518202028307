import { Alignments, AlignmentsValue } from '@komo-tech/core/models/Alignment';
import { Guid } from '@komo-tech/core/models/Guid';
import { MutateAction } from '@komo-tech/core/models/Updates';
import isNil from 'lodash/isNil';

import { SourceValue } from '@/common/models/SourceValue';

import { BlockMarginPadding } from './BlockMarginPadding';
import { BlockItemSizeAutoBehaviour } from './BlockRenderAutoBehaviour';
import { BlockSize } from './BlockSize';
import { BlockStackVariant } from './BlockStackVariant';

export class BlockItemInheritData {
  color?: string;
  fontSize?: number;
  fontFamily?: string;
}

export const mapInheritDataWithSource = (
  data: BlockItemInheritData,
  source?: string
) => {
  const value: Record<string, SourceValue> = {};
  Object.keys(data || {}).forEach((key) => {
    const dataValue = data[key];
    if (!isNil(value)) {
      value[key] = { source, value: dataValue };
    }
  });
  return value as BlockItemInheritSourceData;
};

export class BlockItemInheritSourceData {
  color?: SourceValue<string>;
  fontSize?: SourceValue<number>;
  fontFamily?: SourceValue<string>;
}

export abstract class BlockItemData implements BlockItemInheritData {
  size: BlockSize;
  padding: BlockMarginPadding;
  childStack?: BlockStackVariant;
  hAlign?: Alignments;
  vAlign?: Alignments;
  dataSourceId?: Guid;
  color?: string;
  fontSize?: number;
  fontFamily?: string;

  get alignment(): AlignmentsValue {
    return {
      horizontal: this.hAlign || 'Start',
      vertical: this.vAlign || 'Start'
    };
  }

  get inheritData(): BlockItemInheritData {
    return {
      color: this.color,
      fontFamily: this.fontFamily,
      fontSize: this.fontSize
    };
  }

  abstract defaultAutoBehaviour(): BlockItemSizeAutoBehaviour;

  mutateSize(action: MutateAction<BlockSize>) {
    action(this.size);
  }

  protected mergeDefaults<TSelf extends BlockItemData = BlockItemData>(
    props: Partial<TSelf>
  ): Partial<TSelf> {
    const sanitised: Partial<TSelf> = props || {};
    const autoBehaviour = this.defaultAutoBehaviour();
    if (!sanitised.size) {
      sanitised.size = new BlockSize({
        heightAutoBehaviour: autoBehaviour.height,
        widthAutoBehaviour: autoBehaviour.width
      });

      if (!sanitised.size.widthAutoBehaviour) {
        sanitised.size.widthAutoBehaviour = autoBehaviour.width;
      }

      if (!sanitised.size.heightAutoBehaviour) {
        sanitised.size.heightAutoBehaviour = autoBehaviour.height;
      }
    }

    return sanitised;
  }

  protected setDefaults() {
    this.size = new BlockSize(this.size);
    this.padding = new BlockMarginPadding(this.padding);
    this.dataSourceId = Guid.valueOrUndefined(this.dataSourceId);
  }
}
