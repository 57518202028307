import React, { FC, ReactNode } from 'react';

import { PaginationContext } from './PaginationContext';
import { PaginationType } from './PaginationType';

interface Props {
  pagination: PaginationType;
  children: ReactNode;
}

/**
 * The pagination context provider.
 */
export const PaginationProvider: FC<Props> = ({ pagination, children }) => {
  return (
    <PaginationContext.Provider value={pagination}>
      {children}
    </PaginationContext.Provider>
  );
};
