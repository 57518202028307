import { forwardRef } from 'react';

import { IconProps, tablerIconClasses } from './IconProps';
import { IconSvg } from './IconSvg';

export const AwardIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ className, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest} className={tablerIconClasses(className)}>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <circle cx="12" cy="9" r="6" />
        <polyline
          points="9 14.2 9 21 12 19 15 21 15 14.2"
          transform="rotate(-30 12 9)"
        />
        <polyline
          points="9 14.2 9 21 12 19 15 21 15 14.2"
          transform="rotate(30 12 9)"
        />
      </IconSvg>
    );
  }
);
