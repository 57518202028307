export enum AlignmentsEnum {
  Start = 'Start',
  Center = 'Center',
  End = 'End'
}

export type Alignments = AlignmentsEnum | 'Start' | 'Center' | 'End';

export interface AlignmentsValue {
  horizontal: Alignments;
  vertical: Alignments;
}

export type AlignmentVariant = 'Horizontal' | 'Veritcal';

export class AlignmentFunctions {
  static getLabel(value: Alignments, variant: AlignmentVariant) {
    const isV = variant === 'Veritcal';
    switch (value) {
      case 'Start':
        return isV ? 'Top' : 'Left';
      case 'Center':
        return isV ? 'Middle' : 'Center';
      case 'End':
        return isV ? 'Bottom' : 'Right';
      default:
        return value;
    }
  }

  static toTextCss(align: Alignments) {
    return align === 'End' ? 'right' : align === 'Center' ? 'center' : 'left';
  }

  static toFlexCss(align: Alignments) {
    return align === 'End'
      ? 'flex-end'
      : align === 'Center'
        ? 'center'
        : 'flex-start';
  }

  static toMarginAutoCss(align: Alignments) {
    return align === 'End' ? 'auto 0 0 0' : align === 'Center' ? 'auto 0' : '0';
  }
}
