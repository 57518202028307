import { Box } from '@komo-tech/ui/Box';
import { FC, memo } from 'react';

import { useBlockViewer, useBlockViewerItem } from '../../Viewer/Context';
import { BlockTextItem } from './BlockTextItem';

interface Props {
  overrideText?: string;
  overrideTextLoading?: boolean;
}
export const BlockTextItemViewRenderer: FC<Props> = memo(
  ({ overrideText, overrideTextLoading }) => {
    const hasOverrideText = typeof overrideText === 'string';
    const item = useBlockViewerItem((x) => x.item) as BlockTextItem;
    const register = useBlockViewerItem((x) => x.registerElement);
    const stageScale = useBlockViewer((x) => x.stageScale);

    const { containerStyle, data, textStyle, animationProps } =
      item.getRenderData(stageScale);

    const resolveText = () => {
      if (!hasOverrideText) return data.getFormattedText();
      if (!overrideText) return '';
      return overrideTextLoading ? '' : data.getFormattedText(overrideText);
    };
    return (
      <div style={containerStyle} ref={register}>
        <Box
          __vars={animationProps.vars}
          className={animationProps.className}
          style={textStyle}
        >
          {resolveText()}
        </Box>
      </div>
    );
  }
);
