import { nanoid } from '@komo-tech/core/utils/nanoid';
import { ClampedSizeStyleModel } from '@komo-tech/ui/hooks/useClampedSize';
import { FC, memo, useRef } from 'react';

import { BlockBackgroundRenderer } from '../shared/BlockBackgroundRenderer';
import { BlockViewerItemRenderer } from './BlockViewerItemRenderer';
import { useBlockViewer } from './Context';

interface Props {
  clampedSize: ClampedSizeStyleModel;
}

export const BlockViewerStage: FC<Props> = ({ clampedSize }) => {
  const idRef = useRef(`block-stage-${nanoid()}`);
  const registerContainer = useBlockViewer((x) => x.registerContainer);
  const stage = useBlockViewer((x) => x.stage);
  const stageScale = useBlockViewer((x) => x.stageScale);
  const style = stage.getStyle({
    scale: stageScale,
    style: clampedSize?.styleWithForcedSize
  });
  return (
    <div ref={registerContainer} style={style} id={idRef.current}>
      <BlockBackgroundRenderer
        fallbackColor="white"
        background={stage.data.background}
      />
      <Inner />
    </div>
  );
};

const Inner: FC = memo(() => {
  const itemIds = useBlockViewer((x) => x.stage.childIds);
  return (
    <>
      {itemIds.map((id) => (
        <BlockViewerItemRenderer key={id} itemId={id} />
      ))}
    </>
  );
});
