import { BlockMeasurementUnit } from '../BlockMeasurementUnit';
import { BlockRenderAutoBehaviour } from '../BlockRenderAutoBehaviour';

export class BlockRenderSizeDimension {
  allowedMax?: number;
  max: BlockMeasurementUnit;
  value: BlockMeasurementUnit;
  constructor(props?: Partial<BlockRenderSizeDimension>) {
    props = props || {};
    Object.assign(this, props);
    this.max = new BlockMeasurementUnit(props.max);
    this.value = new BlockMeasurementUnit(props.value);
  }

  getSizeCssValue({ scale, autoBehaviour }: GetSizeCssValueOptions) {
    if (
      this.value.isAuto &&
      this.max.hasPixelValue &&
      autoBehaviour === 'Fill'
    ) {
      return this.max.getCssValue(scale);
    }

    return this.value.getCssValue(scale);
  }

  static auto() {
    return new BlockRenderSizeDimension({
      value: BlockMeasurementUnit.auto()
    });
  }

  static fromPixels(value: number) {
    return new BlockRenderSizeDimension({
      allowedMax: value,
      value: new BlockMeasurementUnit({ value: value, type: 'px' })
    });
  }
}
interface GetSizeCssValueOptions {
  scale: number;
  autoBehaviour: BlockRenderAutoBehaviour;
  ratioMultiplier?: number;
}
