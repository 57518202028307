import { HeightWidth } from '@komo-tech/core/models/HeightWidth';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { PreloadImageData } from '@komo-tech/core/utils/htmlImage';
import { FC } from 'react';

import { Image } from '../Image';

interface Props {
  image: ImageDataModel;
  onFinish: (data: PreloadImageData) => void;
  displaySize?: HeightWidth;
}

export const ImageLoader: FC<Props> = ({ image, displaySize, onFinish }) => {
  return (
    <Image
      key={image.url}
      image={image}
      isPriority
      onCalculateSize={displaySize ? () => displaySize : null}
      onLoad={(e) => {
        onFinish({
          image: e.currentTarget,
          success: true,
          url: image.url
        });
      }}
      onError={(e) => {
        onFinish({
          image: e.currentTarget,
          success: false,
          url: image.url
        });
      }}
    />
  );
};
