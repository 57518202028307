import { useState } from 'react';

import { useInterval } from './useInterval';

interface Props {
  seconds: number;
  onEnded: () => void;
  active: boolean;
}

export const useSimpleTimer = ({
  seconds: secondsProp,
  onEnded,
  active: activeProp
}: Props) => {
  const [seconds, setSeconds] = useState(secondsProp);
  const [isActive, setIsActive] = useState(activeProp);

  const pauseTimer = () => setIsActive(false);
  const resumeTimer = () => setIsActive(true);

  useInterval(
    () => {
      if (seconds === 0) {
        setIsActive(false);
        onEnded?.();
        return;
      }

      if (isActive) {
        setSeconds((s) => s - 1);
      }
    },
    1000,
    false,
    [isActive, seconds],
    isActive
  );

  return { pauseTimer, resumeTimer };
};
