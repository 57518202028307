import {
  createContext,
  useContextSelector
} from '@komo-tech/ui/hooks/useContextSelector';
import { useDeepCompareMemo } from '@komo-tech/ui/hooks/useDeepCompareEffects';
import { RectDataModel, useMeasure } from '@komo-tech/ui/hooks/useMeasure';
import { FCC } from 'fcc';
import { MutableRefObject, useCallback } from 'react';

import { BlockItem } from '../../types/BlockItem';

const invalidContext = () => {
  throw new Error('Cant use outside context');
};

interface ContextProps {
  item: BlockItem;
  itemElementRef: MutableRefObject<HTMLElement>;
  itemBounds: RectDataModel;
  hasItemBounds: boolean;
  registerElement: (element: HTMLElement) => void;
}

const BlockViewerItemContext = createContext<ContextProps>({
  item: undefined,
  hasItemBounds: false,
  itemElementRef: undefined,
  itemBounds: undefined,
  registerElement: invalidContext
});

interface ProviderProps {
  item: BlockItem;
}

export const BlockViewerItemProvider: FCC<ProviderProps> = ({
  item,
  children
}) => {
  const { ref, data, register } = useMeasure();
  const registerElement = useCallback((e: HTMLElement) => {
    if (!ref.current) {
      register(e);
    }
  }, []);
  const value = useDeepCompareMemo<ContextProps>(() => {
    return {
      item,
      itemElementRef: ref,
      itemBounds: data.bounds,
      hasItemBounds: data.hasBounds,
      registerElement
    };
  }, [item, data]);

  return (
    <BlockViewerItemContext.Provider value={value}>
      {children}
    </BlockViewerItemContext.Provider>
  );
};

export function useBlockViewerItem<T>(selector: (value: ContextProps) => T): T {
  return useContextSelector(BlockViewerItemContext, (state) => {
    return selector(state);
  });
}
