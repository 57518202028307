import { BlockButtonItem } from './Items/Button/BlockButtonItem';
import { BlockImageItem } from './Items/Image/BlockImageItem';
import { BlockLayoutItem } from './Items/Layout/BlockLayoutItem';
import { BlockQrCodeItem } from './Items/QrCode/BlockQrCodeItem';
import { BlockTextItem } from './Items/Text/BlockTextItem';
import { ItemResolverFunc } from './types/BlockItem';
import { BlockTypes } from './types/BlockTypes';
import { IBlockItem } from './types/IBlockItem';

export const BlockItemResolver = {
  resolveCustom: (tryResolveFuncs: ItemResolverFunc[]) => {
    tryResolveFuncs ||= [];
    return (item: IBlockItem) => {
      if (item.type === BlockTypes.Custom && !!item.customType) {
        for (let i = 0; i < tryResolveFuncs.length; i++) {
          const resolved = tryResolveFuncs[i]?.(item);
          if (resolved) return resolved;
        }
      }

      return BlockItemResolver.resolve(item);
    };
  },

  resolve: (item: IBlockItem) => {
    switch (item.type) {
      case BlockTypes.Button:
        return new BlockButtonItem(item as BlockButtonItem);
      case BlockTypes.Layout:
        return new BlockLayoutItem(item as BlockLayoutItem);
      case BlockTypes.Text:
        return new BlockTextItem(item as BlockTextItem);
      case BlockTypes.Image:
        return new BlockImageItem(item as BlockImageItem);
      case BlockTypes.QrCode:
        return new BlockQrCodeItem(item as BlockQrCodeItem);
      default:
        throw `Cannot resolve object with type ${item?.type} ${
          (item as any)?.customType
        }`;
    }
  }
};
