'use client';
import { FCC } from '../../fcc';
import { Center, CenterProps } from '../Center';
import { KomoIcon } from '../Icons/KomoIcon';
import classNames from './KomoLoader.module.css';

export interface KomoLoaderProps extends Omit<CenterProps, 'children'> {}

export const KomoLoader: FCC<KomoLoaderProps> = ({
  children,
  h = '100%',
  w = '100%',
  ...rest
}) => (
  <Center h={h} w={w} {...rest}>
    <span className={classNames.LogoWrapper}>
      <KomoIcon size={'xl'} />
    </span>
    {children}
  </Center>
);
