import { HeightWidth } from '@komo-tech/core/models/HeightWidth';

import { BlockMeasurementUnit } from './BlockMeasurementUnit';
import { BlockRenderAutoBehaviour } from './BlockRenderAutoBehaviour';

export class BlockSize {
  static MIN_SIZE = 30;
  widthAutoBehaviour: BlockRenderAutoBehaviour;
  width: BlockMeasurementUnit;
  heightAutoBehaviour: BlockRenderAutoBehaviour;
  height: BlockMeasurementUnit;
  aspectRatio?: HeightWidth;

  get hasAspectRatio() {
    return !!this.aspectRatio;
  }

  constructor(props?: Partial<BlockSize>) {
    props = props || {};
    Object.assign(this, props);
    this.width = new BlockMeasurementUnit(props.width);
    this.height = new BlockMeasurementUnit(props.height);
    if (!this.widthAutoBehaviour) this.widthAutoBehaviour = 'Hug';
    if (!this.heightAutoBehaviour) this.heightAutoBehaviour = 'Hug';
  }

  equals(value: BlockSize) {
    if (!value) return false;
    const sanitised = new BlockSize(value);
    return (
      this.width.equals(sanitised.width) && this.height.equals(sanitised.height)
    );
  }

  static auto() {
    return new BlockSize({
      height: BlockMeasurementUnit.auto(),
      width: BlockMeasurementUnit.auto()
    });
  }

  static fromHeight(height: number) {
    return new BlockSize({
      height: BlockMeasurementUnit.fromPixelValue(height),
      width: BlockMeasurementUnit.auto()
    });
  }

  static fromWidth(width: number) {
    return new BlockSize({
      height: BlockMeasurementUnit.auto(),
      width: BlockMeasurementUnit.fromPixelValue(width)
    });
  }

  static fromSize(size: HeightWidth) {
    return new BlockSize({
      height: BlockMeasurementUnit.fromPixelValue(size.height),
      width: BlockMeasurementUnit.fromPixelValue(size.width)
    });
  }
}
