import { Dictionary } from '@komo-tech/core/models/Dictionary';
import { RectDataModel } from '@komo-tech/ui/hooks/useMeasure';
import isNil from 'lodash/isNil';

export type BlockMeasurementUnitType = 'px';
export class BlockMeasurementUnit {
  value?: number | undefined;
  type: BlockMeasurementUnitType = 'px';

  get hasValue() {
    return !isNil(this.value);
  }

  get hasPixelValue() {
    return this.hasValue && this.type === 'px';
  }

  get isAuto() {
    return !this.hasPixelValue;
  }

  constructor(props?: Partial<BlockMeasurementUnit>) {
    props = props || {};
    Object.assign(this, props);
  }

  getScaledValue(scale: number): number | undefined {
    if (!this.hasValue) return undefined;
    return this.value * scale;
  }

  getCssValue(scale: number): string {
    if (this.isAuto) {
      return 'auto';
    }

    return `${this.getScaledValue(scale)}${this.type}`;
  }

  equals(value: BlockMeasurementUnit) {
    if (!value) return false;
    const sanitised = new BlockMeasurementUnit(value);
    if (this.isAuto) {
      return sanitised.isAuto;
    }

    if (sanitised.isAuto) return false;

    return this.type === sanitised.type && this.value === sanitised.value;
  }

  static auto() {
    return new BlockMeasurementUnit();
  }

  static fromPixelValue(value: number) {
    return new BlockMeasurementUnit({ value, type: 'px' });
  }
}

export interface BlockElementData {
  element?: HTMLElement;
  bounds?: RectDataModel;
  hasBounds?: boolean;
}

export interface BlockItemElementData {
  main: BlockElementData;
  other: Dictionary<BlockElementData>;
}
