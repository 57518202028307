import { Guid } from '@komo-tech/core/models/Guid';

import { DataSourceTypes } from './DataSourceTypes';

export class BlockDataSource {
  type: DataSourceTypes;

  id1?: Guid;
  id2?: Guid;
  id3?: Guid;
  string1?: string;

  get hasAnyIds() {
    return this.hasId1 || this.hasId2 || this.hasId3;
  }

  get hasId1() {
    return !!this.id1;
  }

  get hasId2() {
    return !!this.id2;
  }

  get hasId3() {
    return !!this.id3;
  }

  constructor(props?: Partial<BlockDataSource>) {
    props = props || {};
    Object.assign(this, props);

    this.id1 = Guid.valueOrUndefined(props.id1);
    this.id2 = Guid.valueOrUndefined(props.id2);
    this.id3 = Guid.valueOrUndefined(props.id3);
  }

  isEqual(other: BlockDataSource) {
    return (
      this.type === other.type &&
      Guid.equals(this.id1, other.id1) &&
      Guid.equals(this.id2, other.id2) &&
      Guid.equals(this.id3, other.id3) &&
      this.string1 === other.string1
    );
  }
}
