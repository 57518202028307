import { AlignmentFunctions } from '@komo-tech/core/models/Alignment';
import { ImagesPromiseFunc } from '@komo-tech/core/models/ImageDataModel';
import { asNumber } from '@komo-tech/core/utils/number';
import { CSSProperties, ElementType } from 'react';

import { BlockItem } from '../../types/BlockItem';
import { BlockItemData } from '../../types/BlockItemData';
import { BlockMarginPadding } from '../../types/BlockMarginPadding';
import { BlockItemSizeAutoBehaviour } from '../../types/BlockRenderAutoBehaviour';
import { BlockTypes } from '../../types/BlockTypes';

export class BlockTextItem extends BlockItem<BlockTextItemData> {
  type = BlockTypes.Text;

  constructor(props?: Partial<BlockTextItem>) {
    props = props || {};
    super({
      childOptions: {
        enabled: false
      },
      sizeOptions: {
        aspectRatio: {
          enabled: false
        },
        height: {},
        width: {}
      }
    });
    Object.assign(this, this.sanitiseProps(props));
    this.setDefaults();
  }

  getData(): BlockTextItemData {
    return new BlockTextItemData(this.safeParseJsonData());
  }

  resolveUpdateData(change: Partial<BlockTextItemData>) {
    return new BlockTextItemData({
      ...this.getData(),
      ...change
    });
  }

  resolveImageAssetsFuncAsync(): ImagesPromiseFunc {
    return () => Promise.resolve([]);
  }

  getRenderData(scale: number) {
    const data = this.getData();
    const containerStyle: CSSProperties = {
      ...this.getBaseStyle(scale),
      display: 'grid'
    };

    const autoBehaviour = this.getAutoBehaviour();
    const textStyle: CSSProperties = {
      alignSelf: AlignmentFunctions.toFlexCss(data.vAlign),
      justifySelf: AlignmentFunctions.toFlexCss(data.hAlign),
      textAlign: AlignmentFunctions.toTextCss(data.hAlign),
      fontWeight: data.fontWeightCssValue,
      maxWidth: this.renderSize.width.getSizeCssValue({
        scale,
        autoBehaviour: autoBehaviour.width
      }),
      maxHeight: this.renderSize.height.getSizeCssValue({
        scale,
        autoBehaviour: autoBehaviour.height
      }),
      lineHeight: 1.5,
      color: containerStyle.color || 'inherit',
      fontSize: containerStyle.fontSize || 'inherit',
      fontFamily: containerStyle.fontFamily || 'inherit',
      whiteSpace: 'break-spaces'
    };

    return {
      data,
      renderSize: this.renderSize,
      containerStyle,
      textStyle,
      animationProps: {
        vars: {
          '--fade-transform': 'translateY(-20%)'
        },
        className: 'fadeIn'
      }
    };
  }

  static new(data: Partial<BlockTextItemData> = {}) {
    return new BlockTextItem({
      jsonData: JSON.stringify({
        padding: BlockMarginPadding.fromValue(8),
        hAlign: 'Center',
        ...data
      })
    });
  }
}

export class BlockTextItemData extends BlockItemData {
  text: string;
  elementType: ElementType;
  fontWeight: string;
  get fontWeightCssValue() {
    return asNumber(this.fontWeight, 400);
  }

  prefix?: string;
  suffix?: string;

  constructor(props?: Partial<BlockTextItemData>) {
    super();
    Object.assign(
      this,
      this.mergeDefaults({
        ...BlockTextItemData.defaults,
        ...props
      })
    );
    this.setDefaults();
  }

  static defaults: Partial<BlockTextItemData> = {
    elementType: 'span',
    fontWeight: '400',
    padding: BlockMarginPadding.fromValue(8),
    hAlign: 'Center',
    vAlign: 'Start'
  };

  defaultAutoBehaviour(): BlockItemSizeAutoBehaviour {
    return {
      height: 'Hug',
      width: 'Fill'
    };
  }

  getFormattedText(override?: string) {
    return `${this.prefix || ''}${override || this.text || ''}${
      this.suffix || ''
    }`;
  }
}
