import { FC } from 'react';

import { useBlockViewer, useBlockViewerItem } from '../../Viewer/Context';
import { BlockItemChildrenViewRenderer } from '../BlockItemChildrenViewRenderer';
import { BlockButtonItem } from './BlockButtonItem';

export const BlockButtonItemViewRenderer: FC = () => {
  const layout = useBlockViewerItem((x) => x.item) as BlockButtonItem;
  const register = useBlockViewerItem((x) => x.registerElement);
  const stageScale = useBlockViewer((x) => x.stageScale);
  const { style, viewAttributes, dataAttributes, isLink } =
    layout.getRenderData(stageScale);
  return isLink ? (
    <a style={style} ref={register} {...dataAttributes} {...viewAttributes}>
      <BlockItemChildrenViewRenderer />
    </a>
  ) : (
    <button
      style={style}
      ref={register}
      {...dataAttributes}
      {...viewAttributes}
    >
      <BlockItemChildrenViewRenderer />
    </button>
  );
};
