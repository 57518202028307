import { asBoolean } from '@komo-tech/core/utils/boolean';

export class BlockStageSessionData {
  itemParentsSanitised: boolean;
  itemSizesSanitised: boolean;
  constructor(props?: Partial<BlockStageSessionData>) {
    props = props || {};
    Object.assign(this, props);
    this.itemParentsSanitised = asBoolean(props.itemParentsSanitised);
    this.itemSizesSanitised = asBoolean(props.itemSizesSanitised);
  }
}
