'use client';
import { Pagination as MantinePagination } from '@mantine/core';

import { Group } from '../../Group';
import { PaginationType } from '../PaginationType';

/**
 * @see https://mantine.dev/core/pagination/
 */

interface PaginationV2Props extends PaginationType {
  disabled?: boolean;
}

export const PaginationV2 = ({
  disabled,
  total,
  totalPages,
  pageNumber,
  setPage,
  skip,
  take
}: PaginationV2Props) => {
  return (
    <Group justify="flex-end" gap="0.5rem">
      {(skip + 1).toLocaleString()}-
      {(
        skip + (pageNumber < totalPages ? take : total - skip)
      ).toLocaleString()}{' '}
      of {total.toLocaleString()}
      <MantinePagination
        disabled={disabled}
        value={pageNumber}
        total={totalPages}
        onChange={setPage}
      />
    </Group>
  );
};

export const PaginationV2Alt = MantinePagination;
