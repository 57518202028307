import { FC, memo } from 'react';

import { useBlockViewer, useBlockViewerItem } from '../../Viewer/Context';

export const BlockCustomItemViewRenderer: FC = memo(() => {
  const item = useBlockViewerItem((x) => x.item);
  const customItemDefinitions = useBlockViewer((x) => x.customItemDefinitions);

  if (!item.isCustom()) {
    return <>Invalid item type received by custom renderer</>;
  }

  const definition = customItemDefinitions.find(
    (x) => x.customType === item?.customType
  );

  if (!definition) {
    return (
      <>
        Unable to find custom item renderer for type &#39;{item.customType}
        &#39;
      </>
    );
  }
  return <>{definition.render()}</>;
});
