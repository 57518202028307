import isNil from 'lodash/isNil';
import { useMemo, useState } from 'react';

import { DefaultTake } from './PaginationConstants';
import { PaginationType } from './PaginationType';

/**
 * React hook for accessing pagination context.
 */
export interface PaginationProps extends PaginationType {
  pageIndex: number;
  setPageIndex: (index: number) => void;
  setDefaults: (pageItems: number, totalItems: number) => void;
  firstPage: () => void;
  lastPage: () => void;
  reset: (totalItems?: number) => void;
}

export const usePagination = (
  initialTake?: number,
  totalItems?: number,
  initialPage: number = 1
) => {
  const [take, setTake] = useState(initialTake || DefaultTake);
  const [total, setTotal] = useState(totalItems || 0);
  const [skip, setSkip] = useState(
    (initialPage - 1) * (initialTake || DefaultTake)
  );

  return useMemo(() => {
    const setPage = (value: number) => setSkip((value - 1) * take);
    const setPageIndex = (index: number) => setSkip(index * take);

    const pageNumber = skip == 0 ? 1 : 1 + Math.floor(skip / take);
    const totalPages = Math.ceil(total / take);
    const hasPreviousPage = pageNumber > 1;
    const hasNextPage = pageNumber < totalPages;

    const nextPage = () => {
      if (!hasNextPage) {
        return;
      }
      setSkip(pageNumber * take);
    };

    const previousPage = () => {
      if (!hasPreviousPage) {
        return;
      }
      setSkip((pageNumber - 2) * take);
    };

    const firstPage = () => setSkip(0);
    const lastPage = () => setSkip((totalPages - 1) * take);

    const setDefaults = (pageItems?: number, totalItems?: number) => {
      if (isNil(pageItems) || isNil(totalItems)) return;
      if (pageItems === 0) {
        previousPage();
      }
      setTotal(totalItems);
    };

    const reset = (totalItems?: number) => {
      setTotal(totalItems || 0);
      firstPage();
    };

    const pagination: PaginationProps = {
      take,
      skip,
      total,
      pageNumber,
      pageIndex: pageNumber - 1,
      totalPages,
      hasNextPage,
      hasPreviousPage,
      setTake,
      setSkip,
      setPage,
      setPageIndex,
      setDefaults,
      reset,
      firstPage,
      nextPage,
      previousPage,
      lastPage
    };
    return pagination;
  }, [take, skip, total]);
};
