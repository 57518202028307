//TODO THIS NEEDS TO BE ABSTRACTED OUTISDE OF BLOCKBUILDER SIMILAR TO HOW CUSTOM TYPES ARE ABSTRACTED
export enum DataSourceTypes {
  KomoQuizQuestion = 'KomoQuizQuestion',
  KomoPredictive = 'KomoPredictive',
  KomoPredictiveSegment = 'KomoPredictiveSegment',
  KomoPredictiveQuestion = 'KomoPredictiveQuestion',
  KomoCanvas = 'KomoCanvas',
  KomoTrivia = 'KomoTrivia',
  KomoTriviaQuestion = 'KomoTriviaQuestion',
  KomoUpload = 'KomoUpload',
  KomoMemoryChallenge = 'KomoMemoryChallenge',
  KomoLeaderboard = 'KomoLeaderboard',
  KomoPicker = 'KomoPicker',
  KomoLiveSurvey = 'KomoLiveSurvey',
  KomoContactBadges = 'KomoContactBadges',
  KomoContactProperty = 'KomoContactProperty'
}
