import { Dictionary } from '@komo-tech/core/models/Dictionary';

import { BlockItem } from '../../BlockItem';

export const normaliseStageChildren = (stageItems: BlockItem[]) => {
  const normalisedItems: Dictionary<BlockItem> = {};
  const stageChildIds: string[] = [];
  stageItems.forEach((child) => {
    normaliseInner(null, child).forEach((nc) => {
      if (!nc.parentId) stageChildIds.push(nc.item.id);
      normalisedItems[nc.item.id] = {
        ...nc.item,
        children: []
      } as any;
    });
  });
  return { items: normalisedItems, stageChildIds };
};
const normaliseInner = (parentId: string | null, item: BlockItem) => {
  const selfAndChildren: { item: BlockItem; parentId: string | null }[] = [
    { item, parentId }
  ];
  item.childIds = [];
  (item.children || []).forEach((child) => {
    item.childIds.push(child.id);

    normaliseInner(item.id, child).forEach((nc) => selfAndChildren.push(nc));
  }, this);

  return selfAndChildren;
};
