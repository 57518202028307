import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { CSSProperties } from 'react';

export class BlockBackground {
  color?: string;
  overlayColor?: string;
  backgroundImage?: ImageDataModel;
  videoUrl?: string;

  constructor(props?: Partial<BlockBackground>) {
    props = props || {};
    Object.assign(this, props);
    if (props.backgroundImage) {
      this.backgroundImage = new ImageDataModel(props.backgroundImage);
    }
  }

  getRenderData(fallbackColor?: string) {
    const containerStyle: CSSProperties = {
      position: 'absolute',
      inset: '0px',
      zIndex: 0,
      backgroundColor: this.color || fallbackColor
    };

    const imageStyle: CSSProperties = {
      objectFit: 'cover'
    };
    const overlayStyle: CSSProperties = {
      position: 'absolute',
      inset: '0px',
      zIndex: 0,
      backgroundColor: this.overlayColor
    };

    return {
      hasImage: !!this.backgroundImage?.url,
      hasOverlay: !!this.overlayColor,
      image: this.backgroundImage,
      videoUrl: this.videoUrl,
      hasVideoUrl: !!this.videoUrl,
      containerStyle,
      imageStyle,
      overlayStyle
    };
  }
}
