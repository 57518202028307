import { FCC } from '../../fcc';
import { Center } from '../Center';
import { Spinner, SpinnerProps } from '../Spinner';

export const Loader: FCC<SpinnerProps> = ({ children, ...rest }) => (
  <Center h="100%" w="100%">
    <Spinner {...rest} />
    {children}
  </Center>
);
