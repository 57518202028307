'use client';
import cx from 'clsx';
import { FC } from 'react';

import { IconProps } from './IconProps';
import { IconSvg } from './IconSvg';
import classes from './KomoLogoIcon.module.css';

export const KomoIcon: FC<IconProps> = ({
  color,
  color2,
  className,
  ...rest
}) => {
  return (
    <IconSvg className={cx(classes.komo, className)} {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className={classes.komoPath}
        d="M8.46923 15.0373L2.81519 15.0378V0.75H8.46923V15.0373Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6146 7.59424H14.9709L8.47339 15.0773L14.9709 22.5607H21.6146L14.9709 15.0773L21.6146 7.59424Z"
        className={classes.komoPath}
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.71007 20.2121C8.71007 21.8883 7.3501 23.25 5.67004 23.25C3.99534 23.25 2.63281 21.8883 2.63281 20.2121C2.63281 18.5382 3.99534 17.1765 5.67004 17.1765C7.3501 17.1765 8.71007 18.5382 8.71007 20.2121Z"
        className={classes.dot}
        fill={color2}
      />
    </IconSvg>
  );
};
