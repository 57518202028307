import {
  createContext,
  useContextSelector
} from '@komo-tech/ui/hooks/useContextSelector';
import { useDeepCompareMemo as useMemo } from '@komo-tech/ui/hooks/useDeepCompareEffects';
import { RectReadOnly, useMeasure } from '@komo-tech/ui/hooks/useMeasure';
import { FCC } from 'fcc';
import { MutableRefObject, useCallback, useState } from 'react';

import { DataSourceInstance } from '@/common/components/BlockBuilder/DataSource/DataSourceInstance';

import { CustomItemViewerDefinition } from '../../Items/Custom/types';
import { BlockItem } from '../../types/BlockItem';
import { BlockStageModel } from '../../types/BlockStageModel';

export interface BlockViewerContextProps {
  stage: BlockStageModel;
  stageUtmMedium?: string;
  stageScale: number;
  stageElementRef: MutableRefObject<HTMLDivElement>;
  stageBounds: RectReadOnly;
  registerContainer: (container: HTMLDivElement) => void;
  customItemDefinitions: CustomItemViewerDefinition[];
  isMultiStage: boolean;
}

export const BlockViewerContext = createContext<BlockViewerContextProps>(null);

export interface BlockViewerProviderProps {
  stage?: BlockStageModel;
  stageUtmMedium?: string;
  itemResolver?: (item: BlockItem) => BlockItem;
  customItemDefinitions: CustomItemViewerDefinition[];
  dataSourceInstances?: DataSourceInstance[];
  isMultiStage?: boolean;
  onStageElementRegister?: (element: HTMLDivElement) => void;
}

export const BlockViewerProvider: FCC<BlockViewerProviderProps> = ({
  stage: stageProps,
  stageUtmMedium,
  itemResolver,
  onStageElementRegister,
  customItemDefinitions = [],
  dataSourceInstances = [],
  isMultiStage = false,
  children
}) => {
  const [stage] = useState(
    BlockStageModel.new(stageProps, itemResolver, dataSourceInstances)
  );
  const stageElement = useMeasure<HTMLDivElement>();
  const boundsData = stageElement.data;
  const registerStage = useCallback((e: HTMLDivElement) => {
    if (!stageElement.ref.current) {
      stageElement.register(e);
      onStageElementRegister?.(e);
    }
  }, []);

  const value = useMemo<BlockViewerContextProps>(() => {
    const stageScale = stage.data.resolveScale(stageElement.data.bounds.width);
    return {
      stage,
      stageUtmMedium,
      stageScale,
      stageBounds: boundsData.bounds,
      stageElementRef: stageElement.ref,
      customItemDefinitions,
      registerContainer: registerStage,
      isMultiStage
    };
  }, [stage, stageUtmMedium, boundsData, customItemDefinitions, isMultiStage]);

  return (
    <BlockViewerContext.Provider value={value}>
      {children}
    </BlockViewerContext.Provider>
  );
};

export function useBlockViewer<T>(
  selector: (value: BlockViewerContextProps) => T
): T {
  return useContextSelector(BlockViewerContext, (state) => {
    if (!state) {
      throw new Error(
        'useBlockViewer must be used within a BlockViewerContext'
      );
    }
    return selector(state);
  });
}
