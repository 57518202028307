import { useEffect, useRef } from 'react';

export const useInterval = (
  callback: () => void,
  delay: number | null,
  runOnLoad: boolean = false,
  effectDependencies: any[] = [],
  enabled: boolean = true
) => {
  const savedCallback = useRef<() => void>(callback);
  const savedEnabled = useRef<boolean>(enabled);

  useEffect(() => {
    if (runOnLoad && enabled) {
      callback();
    }
  }, []);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    savedEnabled.current = enabled;
  }, [enabled]);

  useEffect(() => {
    if (!savedEnabled.current) {
      return;
    }
    if (delay !== null) {
      const id = setInterval(() => {
        if (savedCallback.current) {
          savedCallback.current();
        }
      }, delay);
      return () => clearInterval(id);
    }
  }, [...effectDependencies, delay]);
};
