import { AlignmentFunctions } from '@komo-tech/core/models/Alignment';
import {
  deleteFromDictionary,
  Dictionary,
  mapDictionary
} from '@komo-tech/core/models/Dictionary';
import { Guid } from '@komo-tech/core/models/Guid';
import {
  ImageDataModel,
  ImagesPromiseFunc
} from '@komo-tech/core/models/ImageDataModel';
import {
  OperationResult,
  OperationResultType
} from '@komo-tech/core/models/OperationResult';
import { MutateAction } from '@komo-tech/core/models/Updates';
import { mapArray } from '@komo-tech/core/utils/array';
import { nanoid } from '@komo-tech/core/utils/nanoid';
import { filterKeys } from '@komo-tech/core/utils/object';
import isNil from 'lodash/isNil';
import uniq from 'lodash/uniq';
import { CSSProperties } from 'react';
import isEqual from 'react-fast-compare';

import { DataSourceInstance } from '@/common/components/BlockBuilder/DataSource/DataSourceInstance';
import { normaliseStageChildren } from '@/common/components/BlockBuilder/types/_shared/NormaliseMigration/NormaliseMigration';

import { BlockItemResolver } from '../BlockItemResolver';
import { BlockItem, ItemResolverFunc } from './BlockItem';
import {
  BlockItemInheritSourceData,
  mapInheritDataWithSource
} from './BlockItemData';
import { BlockItemTreeNode } from './BlockItemTreeNode';
import { BlockRenderSizeParentDto } from './BlockRenderSize';
import { BlockStageModelData } from './BlockStageModelData';
import { BlockStageSessionData } from './BlockStageSessionData';
import { BlockTypes } from './BlockTypes';
import { IBlockItem } from './IBlockItem';

export class BlockStageModel {
  private sessionData: BlockStageSessionData;

  //Temp prop until all models are normalised
  childrenNormalised: boolean;

  data: BlockStageModelData;
  /**
   * @deprecated Should use items
   */
  children: BlockItem[];
  childIds: string[] = [];
  items: Dictionary<BlockItem> = {};
  itemResolver: ItemResolverFunc;

  get childCount() {
    return (this.childIds || []).length;
  }

  get hasChildren() {
    return !!this.childCount;
  }

  get hasMultipleChildren() {
    return this.childCount > 1;
  }

  /**
   * Override which fields will be serialized - excludes session data
   */
  public toJSON() {
    return filterKeys(this, (key) => key !== 'sessionData');
  }

  constructor(props?: Partial<BlockStageModel>) {
    props = props || {};
    Object.assign(this, props);
    if (!this.sessionData) {
      this.sessionData = new BlockStageSessionData();
    }
    if (!this.children) this.children = [];
    this.data = new BlockStageModelData(props?.data);
    this.childIds = [...(props.childIds || [])];
    this.itemResolver = props.itemResolver || BlockItemResolver.resolve;
    this.resolveChildren();
  }

  getDisplayName(nameOverride?: string): string {
    const isV = this.data.childStack === 'Vertical';
    return `${nameOverride || 'Stage'} ${isV ? '↕️' : '↔️'}`;
  }

  resolveChildren() {
    if (!this.itemResolver) {
      console.error('Failed to resolve items. Missing resolver');
      return;
    }
    if (!this.childrenNormalised) {
      const normaliseResponse = normaliseStageChildren(this.children);
      this.items = normaliseResponse.items;
      this.childIds = [...normaliseResponse.stageChildIds];
      this.children = [];
      this.childrenNormalised = true;
    }

    //Ensure all the items are typed correctly. This also cuts ties
    //to object references
    this.items = mapDictionary(this.items, this.itemResolver);

    //Ensure parent ids are set correctly
    if (!this.sessionData.itemParentsSanitised) {
      this.childIds.forEach((stageChildId) => {
        this.items[stageChildId].setParentId(null);
      });
      Object.keys(this.items).forEach((id) => {
        this.items[id].childIds.forEach((childId) => {
          this.items[childId].setParentId(id);
        }, this);
      }, this);
      this._mutateSessionData((x) => (x.itemParentsSanitised = true));
    }

    //Ensure item sizes have been sanitised
    if (!this.sessionData.itemSizesSanitised) {
      this.sanitiseAllRenderSizes();
      this._mutateSessionData((x) => (x.itemSizesSanitised = true));
    }
  }

  updateItemResolver(itemResolver?: ItemResolverFunc) {
    this.itemResolver = itemResolver || BlockItemResolver.resolve;
    this.resolveChildren();
  }

  getItem({
    id,
    type,
    excludeTypes
  }: GetItemRequest): OperationResult<OperationResultType, BlockItem> {
    if (!this.hasChildren || !id) {
      return null;
    }

    const item = this.items[id];
    if (item) {
      return OperationResult.error(`No item with ${id} exists`);
    }

    if (type && type !== item.type) {
      return OperationResult.error(
        `Expected ${id} to have type ${type} but got ${item.type}`
      );
    }

    if (excludeTypes?.length) {
      if (excludeTypes.some((x) => x === item.type)) {
        return OperationResult.error(
          `Expected ${id} to not be ${excludeTypes.join(', ')} but was ${
            item.type
          }`
        );
      }
    }

    return OperationResult.success({ data: item });
  }

  addItems(
    items: IBlockItem[],
    parentId?: string,
    index?: number
  ): OperationResult {
    const mappedItems = mapArray(items, this.itemResolver) as BlockItem[];

    //If no parent add straight to stage
    if (!parentId) {
      this._addStageItems(mappedItems, index);
      this.sanitiseAllRenderSizes();
      return OperationResult.success();
    }

    if (!this.hasChildren) {
      return OperationResult.error(
        `Error finding parent ${parentId}: Stage has no items`
      );
    }

    const parent = this.items[parentId];
    if (!parent) {
      return OperationResult.error(
        ` Error finding parent. No item with ${parentId} exists`
      );
    }

    const errors = items
      .map((x) => parent.canAcceptItemAsChild(x))
      .filter((x) => !x.success);

    if (!!errors.length) {
      return OperationResult.error(
        `Failed to add items to ${parent.getTypeName()}: ${parentId}. ${errors
          .map((x) => x.errorMessage)
          .join(', ')}`
      );
    }

    this._addNestedItems(parent, mappedItems, index);
    this._sanitiseChildrenRenderSizes(parent.id);
    return OperationResult.success();
  }

  itemExists(id: string) {
    return !!this.items[id];
  }

  moveItem(
    itemId: string,
    parentId: string | null,
    index?: number
  ): OperationResult {
    if (!itemId) {
      return OperationResult.error('No itemId provided');
    }

    if (itemId === parentId) {
      return OperationResult.error(`itemId same as parentId - ${itemId}`);
    }

    const canMoveResult = this._canMoveItem(itemId, parentId);
    if (!canMoveResult.success) {
      return OperationResult.error(
        `Item ${itemId} cannot be moved to ${parentId || 'stage'}: ${
          canMoveResult.errorMessage
        }`
      );
    }

    //Remove from old parent
    const item = this.items[itemId];
    const oldParentId = item.getParentId();
    const sameParent = (!oldParentId && !parentId) || oldParentId === parentId;
    const newParent = this.items[parentId];

    if (!oldParentId) {
      this._removeStageChildLookup(itemId);
    } else {
      //Parent is the same no need to look it up again
      if (sameParent) {
        newParent.removeDirectChildId(itemId);
      } else {
        this.items[oldParentId].removeDirectChildId(itemId);
      }
    }

    //Wer'e adding to stage
    if (!parentId) {
      this._addStageItems([item], index);
    } else {
      //Wer'e adding to an item
      this._addNestedItems(newParent, [item], index);
    }

    //EASIER TO SANITISE ALL THEN FIGURE OUT WHICH ONES NEED FIXING RIGHT NOW
    this.sanitiseAllRenderSizes();

    return OperationResult.success();
  }

  mutateItem(
    itemId: string,
    action: (item: BlockItem) => void
  ): OperationResult {
    const item = this.items[itemId];
    if (!item) {
      return OperationResult.error(`No item with ${itemId} exists`);
    }
    const beforeData = item.getData();
    try {
      action(item);
      const newData = item.getData();
      const shouldCheckRenderSize =
        !isEqual(beforeData.size, newData.size) ||
        !isEqual(beforeData.padding, newData.padding) ||
        !isEqual(beforeData.childStack, newData.childStack);

      if (shouldCheckRenderSize) {
        if (!item.getParentId()) {
          this.sanitiseAllRenderSizes();
        } else {
          this._sanitiseChildrenRenderSizes(item.getParentId());
        }
      }
    } catch (error) {
      return OperationResult.error('Failed to mutate item', {
        errorData: error
      });
    }
    return OperationResult.success();
  }

  deleteItem(
    itemId: string
  ): OperationResult<OperationResultType, DeleteBlockItemData> {
    const item = this.items[itemId];
    if (!item) {
      return OperationResult.error(`No item with ${itemId} exists`);
    }

    this.items = deleteFromDictionary(this.items, itemId);
    let siblingId: string = undefined;
    if (item.getParentId()) {
      const parent = this.items[item.getParentId()];
      parent.removeDirectChildId(itemId);
      siblingId = parent.childIds?.[0];
      this._sanitiseChildrenRenderSizes(parent.id);
    } else {
      this._removeStageChildLookup(itemId);
      siblingId = this.childIds?.[0];
      this.sanitiseAllRenderSizes();
    }

    return OperationResult.success({
      data: {
        parentId: item.getParentId(),
        siblingId
      }
    });
  }

  clone(): BlockStageModel {
    const cloned = new BlockStageModel({
      ...JSON.parse(JSON.stringify(this)),
      itemResolver: this.itemResolver
    });

    const idMap: Dictionary<string> = {};
    const clonedItems: Dictionary<BlockItem> = {};
    Object.keys(cloned.items).forEach((oldId) => {
      const newId = nanoid();
      idMap[oldId] = newId;
      const clonedItem = cloned.items[oldId];

      clonedItem.id = newId;

      clonedItems[newId] = clonedItem;
    });

    cloned.childIds = cloned.childIds.map((x) => idMap[x]);
    Object.keys(clonedItems).forEach((x) => {
      clonedItems[x].childIds = clonedItems[x].childIds.map(
        (oldId) => idMap[oldId]
      );
    });

    cloned.items = clonedItems;
    return cloned;
  }

  cloneItem(itemId: string): OperationResult<OperationResultType, BlockItem> {
    const item = this.items[itemId];
    if (!item) {
      return OperationResult.error(`No item with ${itemId} exists`);
    }

    const clonedData = this._cloneItemAndChildren(item, item.getParentId());
    const clonedItem = clonedData.item;
    const clonedChildren = clonedData.children;
    this.items[clonedItem.id] = clonedItem;
    clonedChildren.forEach((x) => (this.items[x.id] = x), this);

    if (!item.getParentId()) {
      this.childIds.push(clonedItem.id);
      this.sanitiseAllRenderSizes();
    } else {
      const parent = this.items[item.getParentId()];
      parent.childIds.push(clonedItem.id);
      this._sanitiseChildrenRenderSizes(item.getParentId());
    }

    return OperationResult.success({ data: clonedData.item });
  }

  private _cloneItemAndChildren(
    item: BlockItem,
    parentId: string | null
  ): { item: BlockItem; children: BlockItem[] } {
    const cloned = this.itemResolver({
      ...JSON.parse(JSON.stringify(item)),
      childIds: []
    });

    cloned.setParentId(parentId);
    cloned.id = nanoid();

    const clonedChildren: BlockItem[] = [];
    if (item.hasChildren) {
      item.childIds.forEach((id) => {
        const childData = this._cloneItemAndChildren(
          this.items[id],
          cloned.getParentId()
        );
        clonedChildren.push(childData.item);
        childData.children.forEach((x) => clonedChildren.push(x));
      }, this);
    }

    return { item: cloned, children: clonedChildren };
  }

  getStyle(options: {
    scale: number;
    style?: CSSProperties;
    action?: (style: CSSProperties) => void;
  }): CSSProperties {
    const computedStyle: CSSProperties = {
      height: 'auto',
      width: '100%',
      display: 'grid',
      position: 'relative',
      overflow: 'hidden',
      alignContent: 'center',
      color: this.data.color,
      fontSize: (this.data.fontSize || 16) * options.scale,
      fontFamily: this.data.fontFamily,
      padding: this.data.padding.getCssValue(options.scale),
      ...options?.style
    };

    if (this.hasChildren) {
      computedStyle.justifyContent = AlignmentFunctions.toFlexCss(
        this.data.hAlign
      );
      computedStyle.justifyItems = AlignmentFunctions.toFlexCss(
        this.data.hAlign
      );
      computedStyle.alignContent = AlignmentFunctions.toFlexCss(
        this.data.vAlign
      );
      computedStyle.alignItems = AlignmentFunctions.toFlexCss(this.data.vAlign);

      if (this.data.childStack === 'Horizontal') {
        computedStyle.gridTemplateColumns = BlockItem.resolveGridTemplateValues(
          this.childCount
        );
      }

      if (this.data.childStack === 'Vertical') {
        computedStyle.gridTemplateRows = BlockItem.resolveGridTemplateValues(
          this.childCount
        );
      }
    }

    options?.action?.(computedStyle);

    return computedStyle;
  }

  getImageAssetsAsync(): Promise<ImageDataModel[]> {
    const functions: ImagesPromiseFunc[] = [];
    if (this.data?.background?.backgroundImage?.url) {
      functions.push(() =>
        Promise.resolve([this.data.background?.backgroundImage])
      );
    }

    Object.keys(this.items).forEach(
      (id) => functions.push(this.items[id].resolveImageAssetsFuncAsync()),
      this
    );

    if (!functions.length) {
      return Promise.resolve([]);
    }

    return Promise.all(functions.map((func) => func())).then((matrix) => {
      const images: ImageDataModel[] = [];
      matrix.forEach((m) => {
        m.forEach((i) => {
          images.push(i);
        });
      });
      return images;
    });
  }

  getItems(filter?: GetItemsFilter): BlockItem[] {
    if (!this.hasChildren) {
      return [];
    }

    const ids: Dictionary<string> = {};
    if (filter?.itemIds?.length) {
      filter.itemIds.forEach((x) => (ids[x] = 'true'));
    }

    const hasTypes = !!filter?.types;
    const hasIds = !!Object.keys(ids).length;
    return Object.keys(this.items)
      .filter((id) => {
        if (hasIds && !ids[id]) {
          return false;
        }

        const item = this.items[id];
        if (hasTypes && !filter.types.some((t) => t === item.type)) {
          return false;
        }

        if (filter?.parentId) {
          const parent = this.items[filter.parentId];
          return !!parent && parent.childIds.some((x) => x === id);
        }

        if (filter?.isCustom && !item.isCustom()) {
          return false;
        }

        return true;
      }, this)
      .map((id) => this.items[id], this);
  }

  getUsedCustomTypes() {
    return uniq(
      this.getItems({ types: [BlockTypes.Custom] })
        .map((i) => i?.customType)
        .filter((x) => !!x)
    );
  }

  getDataSourceIds(options?: { unique?: boolean }) {
    let ids = this.getItems({ isCustom: true })
      .map((x) => x.getDataSourceId())
      .filter((x) => !!x);

    if (options?.unique) {
      ids = ids.filter(Guid.uniqueFilter);
    }

    return ids;
  }

  sanitiseAllRenderSizes() {
    if (!this.hasChildren) {
      return;
    }

    const stageRenderSize = this.data.getRenderSize();
    this.childIds.forEach((id) => {
      this._sanitiseItemRenderSize(
        id,
        {
          size: stageRenderSize,
          stack: this.data.childStack,
          padding: this.data.padding
        },
        this.childIds
      );
    }, this);
  }

  setDataSourceInstances(dataSources: DataSourceInstance[]) {
    if (!this.hasChildren) {
      return;
    }

    Object.keys(this.items).forEach(
      (id) => this.items[id].trySetDataSourceInstance(dataSources),
      this
    );
  }

  updateData(change: Partial<BlockStageModelData>) {
    const renderSizeBefore = this.data.getRenderSize();
    const paddingBefore = this.data.padding;
    const childStackBefore = this.data.childStack;
    this.data = new BlockStageModelData({
      ...this.data,
      ...change
    });
    const renderSizeChanged = !isEqual(
      renderSizeBefore,
      this.data.getRenderSize()
    );
    const paddingChanged = !isEqual(paddingBefore, this.data.padding);
    const stackChanged = childStackBefore !== this.data.childStack;
    if (renderSizeChanged || paddingChanged || stackChanged) {
      this.sanitiseAllRenderSizes();
    }
  }

  getAllItemTreeNodes(): Dictionary<BlockItemTreeNode> {
    return BlockItemTreeNode.dictionaryFromStage({
      allItems: this.items,
      stageChildIds: this.childIds,
      stageInheritData: this.data.inheritData
    });
  }

  getTopLevelTreeNodes(): BlockItemTreeNode[] {
    return this.childIds.map(
      (itemId) =>
        this.getSingleItemTreeNode({
          itemId,
          parent: {
            id: null,
            inheritData: mapInheritDataWithSource(
              this.data.inheritData,
              'stage'
            )
          }
        }),
      this
    );
  }
  getSingleItemTreeNode(options: {
    itemId: string;
    parent: {
      id: string | null;
      inheritData: BlockItemInheritSourceData;
    };
  }): BlockItemTreeNode {
    return BlockItemTreeNode.fromItem({
      allItems: this.items,
      itemId: options.itemId,
      parent: options.parent
    });
  }

  private _sanitiseItemRenderSize(
    itemId: string,
    parent: BlockRenderSizeParentDto,
    siblingsAndSelfIds: string[]
  ) {
    this.items[itemId].sanitiseRenderSize({
      parent,
      siblingsAndSelf: siblingsAndSelfIds.map(
        (id) => ({
          id: id,
          size: this.items[id].getData().size,
          aspectRatio: this.items[id].getAspectRatioData()
        }),
        this
      )
    });

    this._sanitiseChildrenRenderSizes(itemId);
  }

  private _sanitiseChildrenRenderSizes(itemId: string) {
    const item = this.items[itemId];
    const childrenIds = item.childIds;
    if (!childrenIds.length) {
      return;
    }

    const parentSize = item.getBlockRenderSizeParentDto();
    item.childIds.forEach((id) => {
      this._sanitiseItemRenderSize(id, parentSize, childrenIds);
    }, this);
  }

  private _canMoveItem(
    itemId: string,
    newParentId: string | null
  ): OperationResult {
    const item = this.items[itemId];
    if (!item) {
      return OperationResult.error(`No item with ${itemId} exists`);
    }

    //Stage can have all items
    if (!newParentId) return OperationResult.success();

    //if its the same parent we know that parent can have this item as a child
    if (newParentId === item.getParentId()) return OperationResult.success();

    const newParent = this.items[itemId];
    if (!newParent) {
      return OperationResult.error(
        `Cannot find parent. No item with ${newParentId} exists`
      );
    }

    const result = newParent.canAcceptItemAsChild(item);
    if (!result.success) {
      return OperationResult.error(
        `Parent ${newParentId} cannot take item: ${result.errorMessage}`
      );
    }

    return OperationResult.success();
  }

  private _addStageItems(items: BlockItem[], index?: number) {
    let hasIndex = !isNil(index);
    if (hasIndex) {
      if (!this.childIds?.[index]) {
        hasIndex = false;
      }
    }

    items.forEach((item, itemIndex) => {
      item.setParentId(null);
      this.items[item.id] = item;
      if (!hasIndex) {
        this.childIds.push(item.id);
      } else {
        this.childIds.splice(index + itemIndex, 0, item.id);
      }
    }, this);
  }

  private _addNestedItems(
    parent: BlockItem,
    items: BlockItem[],
    index: number | null
  ) {
    let hasIndex = !isNil(index);
    if (hasIndex) {
      if (!parent.childIds?.[index]) {
        hasIndex = false;
      }
    }

    items.forEach((item, itemIndex) => {
      item.setParentId(parent.id);
      this.items[item.id] = item;
      if (!hasIndex) {
        parent.childIds.push(item.id);
      } else {
        parent.childIds.splice(index + itemIndex, 0, item.id);
      }
    }, this);
  }

  private _removeStageChildLookup(itemId: string) {
    this.childIds = this.childIds.filter((x) => x !== itemId);
  }

  private _mutateSessionData(action: MutateAction<BlockStageSessionData>) {
    this.sessionData = new BlockStageSessionData(this.sessionData);
    action(this.sessionData);
  }

  static new(
    stage: BlockStageModel,
    itemResolver: ItemResolverFunc,
    dataSources: DataSourceInstance[]
  ) {
    const renderStage = new BlockStageModel({
      ...stage,
      itemResolver
    });
    renderStage.setDataSourceInstances(dataSources || []);
    return renderStage;
  }

  static fromSize(options: { maxWidth: number; maxHeight?: number }) {
    const stage = new BlockStageModel();
    stage.data.size.maxWidth = options.maxWidth;
    stage.data.size.maxHeight = options.maxHeight;
    return stage;
  }
}

interface GetItemsFilter {
  types?: BlockTypes[];
  isCustom?: boolean;
  itemIds?: string[];
  parentId?: string;
}

interface GetItemRequest {
  id: string;
  type?: BlockTypes;
  excludeTypes?: BlockTypes[];
}

export interface DeleteBlockItemData {
  parentId?: string;
  siblingId?: string;
}
